
.App {
  display: flex;
  /* color: #38aae2; */
  flex-direction: column;
  /* background: linear-gradient(179deg, #fff, #ebf4ff); Updated gradient */
  min-height: 100vh;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.footer {
  width: 100%;
}

.header, .footer {
  width: 100%;
}

.logo-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer a:hover {
  text-decoration: underline;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  background-color: #000;
}

/* Add a class to enable transitions AFTER the initial load */
body.bg-transition {
  transition: background-color 1s ease; /* Smooth background transition */
}

h1, h2 {
  color: #333;
}

h2 {
  line-height: 2;
  font-weight: bold;
    /* line-height: .9; */
    font-family: "univers67condensedbold", sans-serif;
    color: #fff;
    margin-bottom: 0px;
}

p, b, li {
  color: #c3c3c3;
}

p {
  font-size: 1.2rem;
}

a {
  color: inherit;
  text-decoration: none;
}

.text-container {
  width: 100%;
  max-width: 600px; /* Maximum width for larger screens */
  margin: 0 auto; /* Center the container */
}

.app-image {
  width: 100%;
  margin-bottom: 25px;
  margin-top: 25px;
  height: auto;
  display: block;
  -webkit-mask-image: linear-gradient(to top, transparent, black 50%, #00000082 90%, transparent),
                       linear-gradient(to bottom, transparent, transparent 50%, transparent 90%, transparent),
                       linear-gradient(to left, transparent, black 50%, black 90%, transparent),
                       linear-gradient(to right, transparent, black 50%, black 90%, transparent);
  mask-image: linear-gradient(to top, transparent, black 50%, #00000082 90%, transparent),
              linear-gradient(to bottom, transparent, transparent 50%, transparent 90%, transparent),
              linear-gradient(to left, transparent, black 50%, black 90%, transparent),
              linear-gradient(to right, transparent, black 50%, black 90%, transparent);
  -webkit-mask-mode: alpha;
  mask-mode: alpha;
  -webkit-mask-composite: destination-out;
  mask-composite: subtract;
  opacity: 50%;
}

/* Dark mode */
body[data-theme='dark'] {
  background-color: #000;
  color: #38aae2;
}

body[data-theme='dark'] .navbar-brand,
body[data-theme='dark'] .nav-link {
  color: #6a6a6a !important;
}

body[data-theme='dark'] .navbar-brand:hover,
body[data-theme='dark'] .nav-link:hover {
  color: #ccc !important;
}

body[data-theme='dark'] .icon {
  color: #38aae2;
}

/* Customer Input Text Field */
.custom-input::placeholder {
  color: rgba(255, 255, 255, 0.5); /* Light white with reduced opacity */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
