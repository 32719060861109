.theme-switcher {
  background: none;
  border: none;
  margin-top: 0px;
  cursor: pointer;
  color: #38aae2;
  font-size: 1.3rem;
  text-align: end;
}

.theme-switcher:hover {
  color: #1f8bb0;
}
