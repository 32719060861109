/* Footer.css */

.footer-container {
  width: 100%; /* Ensure it spans the full width */
  padding-left: 15px; /* Matches Bootstrap's default padding */
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1140px; /* Bootstrap's default container width at large screens */
}

@media (max-width: 767.98px) {
  .footer .row {
    flex-direction: column;
  }

  .footer .row .col-12 {
    margin-bottom: 10px;
  }
}

.footer {
  font-size: 12px;
  color: #3c3c3c;
}

.footer a {
  color: #3c3c3c;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.ycLogo {
  width: 15px;
  margin-left: 5px;
  border-radius: 2px;
  margin-top: -3px;
}
