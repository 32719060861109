.partner-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  text-align: center;
  margin-bottom: 30px;
}

.benefits, .integrations, .revenue-calculator, .integration-steps, .sign-up {
  margin-bottom: 60px; /* Increased spacing between sections */
}

.benefits ul {
  list-style: none;
  padding: 0;
}

.benefits ul li {
  margin-bottom: 10px;
}

.calculator-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.revenue-amount {
  font-size: 2.5em;
  font-weight: bold;
  margin-top: 20px;
}

.sign-up {
  text-align: center;
}

.sign-up a {
  color: #38aae2;
  text-decoration: none;
}

.sign-up a:hover {
  text-decoration: underline;
}

.light h1, .light h2, .light p, .light ul li {
  color: #000;
}

.dark h1, .dark h2, .dark p, .dark ul li {
  color: #fff;
}

.revenue-calculator {
  background: linear-gradient(135deg, #41aacc, #2b2fe2);
  padding: 20px;
  border-radius: 10px;
  color: white;
}

.company-logos-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 300px; /* Adjust the height as needed */
}

.company-logos {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
}

.company-logos::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 100%);
  pointer-events: none;
}

@media (max-width: 768px) {
  .integrations-section {
    background-image: none !important;
  }
  .revenue-calculator .row,
  .integration-steps .row {
    text-align: center;
    flex-direction: column;
  }

  .calculator-form {
    margin-top: 20px;
  }

  .company-logos::before {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 100%);
  }
}

.accordion, .accordion-button:not(.collapsed), .accordion-item {
  --bs-accordion-bg: var(--bg-color);
  background-color: var(--bg-color);
  color: var(--p-text-color) !important;
  border: none !important;
}

.accordion-button, .accordion-button::after {
  color: var(--p-text-color) !important;
}

.accordion-body {
  padding: 20px;
  text-align: left;
}


.accordion-button::after {
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);
}

.accordion-item:not(:first-of-type) {
  border-top: 1px solid !important;
}

.accordion-button {
  padding: 20px 0 !important;
  font-size: 1.3rem !important;
}

.header-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 75vh;
}

.revenue-calculator h2 {
  font-weight: 800;
}

.last-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 60vh;
}

.last-section a.btn {
  background: linear-gradient(90deg, #53b5e3, #2454e4);
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-weight: bold;
  margin-top: 0px;
}

.quote-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Full page height */
  padding: 20px;
  background-color: var(--bg-color);
  /* background-color: red; */
  color: var(--text-color);
}

.neon-text {
  font-weight: 800;
  /* animation: flicker 1.5s infinite alternate; */
}

.dark .header-section h5 {
  color: #fff;
}
.header-section h5 {
  color: #000;
}


/* Flickering animation */
@keyframes flicker {

  0%, 18%, 22%, 25%, 53%, 57%, 100% {

      text-shadow:
      0 0 4px #fff,
      0 0 11px #fff,
      0 0 19px #fff,
      0 0 40px rgb(0, 119, 255),
      0 0 80px rgb(0, 119, 255),
      0 0 90px rgb(0, 119, 255),
      0 0 100px rgb(0, 119, 255),
      0 0 150px rgb(0, 119, 255);

  }

  20%, 24%, 55% {
      text-shadow: none;
  }
}


.statement-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  min-height: 75vh; /* Full page height */
  padding: 0px;
  background-color: var(--bg-color);
  /* background-color: blue; */
  color: var(--text-color);
  /* font-size: 1.75rem; */
}

.integration-steps {
  display: flex;
  min-height: 75vh;
  justify-content: center;
  align-items: stretch;
}

.revenue-section {
  display: flex;
  min-height: 75vh;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
}

.hr {
  border-top: dashed 4px !important;
  margin-top: 100px;
  margin-bottom: 100px
}

.integrations-section {
  min-height: 75vh;
  display: flex;
  align-items: center;
  background-image: url('../../assets/images/companies.png');
  background-size: contain;
  /* box-shadow: inset 0px 9px 251px 193px var(--bg-color); */
  /* box-shadow: inset 100px 372px 529px 712px #000000cc; */
}

.dark .intgrations-section {
  box-shadow: inset 100px 372px 529px 712px #000000cc;
}

.statement-section h4 {
  font-weight: bold;
  text-wrap: nowrap;
}
