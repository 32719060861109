
.header-container {
  width: 100%; /* Ensure it spans the full width */
  padding-left: 15px; /* Matches Bootstrap's default padding */
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1140px; /* Bootstrap's default container width at large screens */
}

.navbar {
  background-color: var(--bg-color);
}

.navbar-brand {
  font-weight: normal;
  color: #38aae2 !important;
  font-size: 12px;
}

.navbar-brand:hover {
  color: #1f8bb0 !important; /* Optional: a slightly darker shade for hover effect */
}

.nav-link {
  color: #6a6a6a !important;
  font-size: 12px;
}

.nav-link:hover {
  color: #6a6a6a !important;
}

.navbar-toggler {
  border: none !important; /* Remove border from hamburger icon */
}

.icon {
  color: #38aae2;
  font-size: 1.5rem;
}

.theme-switcher {
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  color: #38aae2;
  font-size: 1.5rem;
  margin-right: 10px; /* Adjust margin as needed */
}

.theme-switcher:hover {
  color: #1f8bb0;
}

@media (max-width: 768px) {
  .theme-switcher {
    margin-right: 0;
  }
}
