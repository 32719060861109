/* #### Generated By: http://font.download #### */

    @font-face {
    font-family: 'Neue Plak Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Regular'), url('Neue Plak Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Black';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Black'), url('Neue Plak Black.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Bold'), url('Neue Plak Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Condensed Black';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Condensed Black'), url('Neue Plak Condensed Black.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Condensed Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Condensed Bold'), url('Neue Plak Condensed Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Condensed Extra Black';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Condensed Extra Black'), url('Neue Plak Condensed ExtraBlack.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Condensed Light';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Condensed Light'), url('Neue Plak Condensed Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Condensed';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Condensed'), url('Neue Plak Condensed Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Condensed SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Condensed SemiBold'), url('Neue Plak Condensed SemiBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Condensed Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Condensed Thin'), url('Neue Plak Condensed Thin.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Condensed Ultra Light';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Condensed Ultra Light'), url('Neue Plak Condensed UltraLight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Extended Black';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Extended Black'), url('Neue Plak Extended Black.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Extended Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Extended Bold'), url('Neue Plak Extended Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Extended Extra Black';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Extended Extra Black'), url('Neue Plak Extended ExtraBlack.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Extended Light';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Extended Light'), url('Neue Plak Extended Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Extended';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Extended'), url('Neue Plak Extended Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Extended Semi Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Extended Semi Bold'), url('Neue Plak Extended SemiBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Extended Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Extended Thin'), url('Neue Plak Extended Thin.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Extended Ultra Light';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Extended Ultra Light'), url('Neue Plak Extended UltraLight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Extra Black';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Extra Black'), url('Neue Plak ExtraBlack.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Light';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Light'), url('Neue Plak Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak SemiBold'), url('Neue Plak SemiBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Text Black';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Text Black'), url('Neue Plak Text Black.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Text Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Text Bold'), url('Neue Plak Text Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Text Light';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Text Light'), url('Neue Plak Text Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Text SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Text SemiBold'), url('Neue Plak Text SemiBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Text Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Text Thin'), url('Neue Plak Text Thin.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Thin'), url('Neue Plak Thin.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Ultra Light';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Ultra Light'), url('Neue Plak UltraLight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Text Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Text Black Italic'), url('Neue Plak Text Black Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Text Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Text Bold Italic'), url('Neue Plak Text Bold Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Text Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Text Italic'), url('Neue Plak Text Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Text Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Text Light Italic'), url('Neue Plak Text Light Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Text SemiBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Text SemiBold Italic'), url('Neue Plak Text SemiBold Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Neue Plak Text Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Neue Plak Text Thin Italic'), url('Neue Plak Text Thin Italic.woff') format('woff');
    }