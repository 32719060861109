/* Center the title and subtitle perfectly */
main.content {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers vertically */
    align-items: center; /* Centers horizontally */
    height: 100%; /* Take up full container height */
    width: 100%; /* Take up full container width */
    text-align: center;
    position: relative; /* For positioning */
  }
  
  /* Update .container to ensure vertical centering works */
  .container {
    color: #fff;
    text-align: center;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .content .title-subtitle-wrapper {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%); /* Perfect centering */
    text-align: center;
    width: 100%;
  }
  
  
  .container.bg-transition {
    background-color: #1e1e3f; /* New background color */
  }
  
  .view, .signup-view {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0;
  }

  .view {
    transform: translateX(100%);
    }
  
  .slide-out {
    transform: translateX(-100%);
  }
  
  .slide-in {
    transform: translateX(0);
    opacity: 1;
  }
  
  /* Home.css */

@keyframes hue-rotate {
    0% {
      filter: hue-rotate(295deg); /* Start from 295deg */
    }
    100% {
      filter: hue-rotate(655deg); /* 360deg rotation + starting offset */
    }
  }
  
  .header .logo {
    width: clamp(50px, 6vw, 50px);
    margin-bottom: 20px;
    margin-top: 10vh;
    animation: hue-rotate 5s infinite linear; /* Smooth, infinite animation */
  }

  .yc-logo {
    margin-bottom: 20px;
    filter: grayscale(100%) brightness(40%) opacity(0.9); /* Base image settings */
    position: absolute;
    margin: 0 auto;
    right: calc(50% - clamp(50px, 3vw, 60px));
    width: clamp(100px, 6vw, 120px);
    top: 12vh;

    /* Shine effect using mask */
    -webkit-mask-image: linear-gradient(45deg, rgba(255, 255, 255, .6) 25%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, .6) 75%);
    mask-image: linear-gradient(45deg, rgba(255, 255, 255, .6) 25%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, .6) 75%);
    -webkit-mask-size: 300%;
    mask-size: 300%;
    -webkit-mask-position: 0%;
    mask-position: 0%;
    transition: filter .3s ease; /* Smooth transitions for hover */

    /* Animation */
    animation: shineEffect 5s linear infinite;
}

@keyframes shineEffect {
    0% {
        -webkit-mask-position: 0%;
        mask-position: 0%;
    }
    100% {
        -webkit-mask-position: 300%;
        mask-position: 300%;
    }
}

.yc-logo:hover {
    animation: none;

    filter: grayscale(0%) brightness(100%) opacity(1); /* Highlight image on hover */
}



  
  
  .content .title {
    font-size: clamp(20px,13vw,98px);
    font-weight: bold;
    line-height: .9;
    font-family: "univers67condensedbold", sans-serif;
    color: #fff;
    margin-bottom: 0px;
  }
  
  .content .highlight {
    color: #3aace3;
    animation: hue-rotate 5s infinite linear; /* Smooth, infinite animation */
    filter: hue-rotate(295deg); /* Start at the same hue-rotate angle */
    letter-spacing: -2px;
    background: -webkit-linear-gradient(295deg, #ff0bfb, #edff00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  
  .content .subtitle {
    font-family: 'Neue Plak Regular', sans-serif;
    padding: 0;
    margin: 5px 0 0;
    font-size: clamp(16px, 3.5vw, 24px);
    line-height: 1.5;
    text-align: center;
    color: white;
    text-transform: capitalize;
  }
  
  .access-button, .submit-button {
    background: transparent;
    border: 2px solid #fff;
    color: #3aace3;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 30px;
    cursor: pointer;
    border-radius: 15px;
    transition: all 0.3s ease;
    filter: hue-rotate(295deg); /* Start at the same hue-rotate angle */
    animation: hue-rotate 5s infinite linear; /* Smooth, infinite animation */
    width: fit-content;
    background: -webkit-linear-gradient(295deg, #ff0bfb, #edff00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.access-button-subtitle {
    font-family: 'Neue Plak Regular', sans-serif;
    margin-top: 5px;
    color: #888888;
    font-size: 12px;
}

.content .descriptive-title {
    font-size: clamp(30px,7vw,85px); /* clamp(20px,13vw,98px); */
    font-weight: bold;
    line-height: .9;
    font-family: "Neue Plak Regular", sans-serif;
    color: black;
    margin-bottom: 0px;
  }

.access-button-wrapper {
    margin-top: 5vh;
    margin-bottom: 15vh;
    bottom: 0;
    position: absolute;
}
  
  .access-button:hover, .submit-button:hover {
    background-color: #3aace3;
    color: #000;
    filter: hue-rotate(295deg); 
  }
  
  .signup-form .input-field {
    display: block;
    margin: 10px 0;
    padding: 10px;
    width: 250px;
    border: 2px solid #a499ff;
    border-radius: 5px;
    color: #000;
    font-size: 1rem;
  }
  
  .view {
    z-index: 100;
  }

  .view.slide-in {
    z-index: 1000 !important;
  }

  .access-button.circle {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    border: none;
  }
  
  .question-mark { 
    opacity: 0.2;
    font-size: 14px;
    margin-bottom: 2px;
    margin-left: 5px;
  }

  .dashed-text {
    /* text-decoration: underline; */
    text-decoration-color: #FFF
    ;
    text-decoration-style: dashed;
    text-decoration-thickness: 1px;
    text-underline-offset: 3px;
  }

.inviter-container {
  display: flex;
  align-items: center;
  background-color: rgb(17 17 17);
  padding: 2px 20px;
  border-radius: 40px;
  margin-top: 50px;
  position: relative;
  z-index: 1000;
}

.inviter-image {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  margin-right: 10px;
  object-fit: cover;
}

.invite-text {
  color: white;
  padding: 15px 0;
  font-size: 16px;
  text-align: center;
  font-family: 'Neue Plak Regular', sans-serif;
}

.access-button-subtitle {
  color: rgba(255, 255, 255, 0.3);
  text-align: center;
  margin-top: 8px;
  font-family: 'Neue Plak Regular', sans-serif;
}

/* Update access-button-wrapper positioning */
.access-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5vh;
  margin-bottom: 15vh;
  bottom: 0;
  position: absolute;
}