/* Regular */
@font-face {
    font-family: "Univers Next Pro";
    src: url("./UniversNextProRegular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

/* Italic */
@font-face {
    font-family: "Univers Next Pro";
    src: url("./UniversNextProItalic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

/* Light */
@font-face {
    font-family: "Univers Next Pro";
    src: url("./Univers Next Pro Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

/* Light Italic */
@font-face {
    font-family: "Univers Next Pro";
    src: url("./Univers Next Pro Light Italic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}

/* Bold */
@font-face {
    font-family: "Univers Next Pro";
    src: url("./UniversNextProBold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

/* Bold Italic */
@font-face {
    font-family: "Univers Next Pro";
    src: url("./UniversNextProBoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
}

/* Medium */
@font-face {
    font-family: "Univers Next Pro";
    src: url("./Univers Next Pro Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

/* Medium Italic */
@font-face {
    font-family: "Univers Next Pro";
    src: url("./Univers Next Pro Medium Italic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}

/* Thin */
@font-face {
    font-family: "Univers Next Pro";
    src: url("./UniversNextProThin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}

/* Thin Italic */
@font-face {
    font-family: "Univers Next Pro";
    src: url("./UniversNextProThinItalic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
}

/* Condensed */
@font-face {
    font-family: "Univers Next Pro Condensed";
    src: url("./Univers Next Pro Condensed.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

/* Condensed Italic */
@font-face {
    font-family: "Univers Next Pro Condensed";
    src: url("./UniversNextProCondItalic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

/* Heavy */
@font-face {
    font-family: "Univers Next Pro";
    src: url("./Univers Next Pro Heavy.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

/* Heavy Italic */
@font-face {
    font-family: "Univers Next Pro";
    src: url("./Univers Next Pro Heavy Italic.ttf") format("truetype");
    font-weight: 800;
    font-style: italic;
}

/* ExtraBlack */
@font-face {
    font-family: "Univers Next Pro";
    src: url("./Univers Next Pro ExtraBlack Extended.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

/* ExtraBlack Italic */
@font-face {
    font-family: "Univers Next Pro";
    src: url("./Univers Next Pro ExtraBlack Italic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}

/* Univers 67 */
@font-face {
    font-family: 'univers67condensedbold';
    src: 
        url("./univers67condensedbold.svg") format('svg'), 
        url("./univers67condensedbold.otf") format('opentype'),
        url("./univers67condensedbold.ttf") format('truetype'),
        url("./univers67condensedbold.woff2") format('woff2');
    font-weight: 400;
    font-style: normal;
}
